import React, {FC, memo, TransitionEvent, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import departmentsArea from "../../../services/departmentsArea";
import dynamic from 'next/dynamic'
import {IState} from "../../../types/state";
import {DepartmentsSvg} from "../../../svg";
import {IProps} from "../../../types/homePageTypes";
import {PropsConsumer} from "../../../context";
import cls from "../header.module.scss"

const DepartmentsLinks = dynamic(() => import('./DepartmentsLinks'))

const Departments: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [fixed, setFixed] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [unsubscribeAria, setUnsubscribeAria] = useState<any>(null);
    const [area, setArea] = useState<any>(null);
    let rootRef = useRef<any>(null);
    const dispatch = useDispatch()
    const categories = useSelector((state: IState) => state.general.categories)

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        setFixed(!!departmentsArea.area)
        setArea(departmentsArea.area)

        /************************/

        const root = rootRef.current;
        // const content = root.querySelector(".departments__links-wrapper");

        // if (fixed) {
        //     const areaRect = area.getBoundingClientRect();
        //     const areaBottom = areaRect.top + areaRect.height + window.scrollY;
        //     root.classList.remove("departments--transition");
        //     root.classList.add("departments--fixed", "departments--opened");
        //
        //     const height = areaBottom - (content.getBoundingClientRect().top + window.scrollY);
        //
        //     content.style.height = `${height}px`;
        //     content.getBoundingClientRect();
        // } else if (!fixed) {
        //     if (open) {
        //         const root = rootRef.current;
        //
        //         const content = root.querySelector(".departments__links-wrapper");
        //         content.getBoundingClientRect();
        //         const startHeight = content.getBoundingClientRect().height;
        //
        //         root.classList.add("departments--transition", "departments--opened");
        //
        //         const endHeight = content.getBoundingClientRect().height;
        //
        //         content.style.height = `${startHeight}px`;
        //         content.getBoundingClientRect();
        //         content.style.height = `${endHeight}px`;
        //     } else {
        //         const root = rootRef.current;
        //         const content = root.querySelector(".departments__links-wrapper");
        //         const startHeight = content.getBoundingClientRect().height;
        //
        //
        //         content.style.height = `${startHeight}px`;
        //
        //         root.classList.add("departments--transition");
        //         root.classList.remove("departments--opened");
        //
        //         content.getBoundingClientRect();
        //         content.style.height = "";
        //     }
        // } else {
        //     root.classList.remove("departments--opened", "departments--fixed");
        //     content.style.height = "";
        // }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    }, [fixed, open, area])

    const setWrapperRef = (node: any) => {
        rootRef.current = node;
    };

    function handleOutsideClick(e: any) {
        if (rootRef.current && !rootRef.current.contains(e.target)) {
            setOpen(false)
        }
    }

    const handleButtonClick = () => {
        dispatch({type: "MOBILE_MENU_OPEN", payload: true});
        setOpen(!open);
        setActive(!active);
    };

    const handleTransitionEnd = (event: TransitionEvent<HTMLDivElement>) => {

        if (rootRef.current) {
            rootRef.current.querySelector(`.${cls["departments__links-wrapper"]}`).style.height = "";
            rootRef.current.classList.remove(cls["departments--transition"]);
        }
    };

    return (
        <div className={cls.departments} ref={setWrapperRef}>
            {/*<div className={cls.departments__body}>*/}
            {/*    <div*/}
            {/*        className={cls["departments__links-wrapper"]}*/}
            {/*        onTransitionEnd={handleTransitionEnd}*/}
            {/*    >*/}
            {/*        <PropsConsumer>*/}
            {/*            {*/}
            {/*                props => (*/}
            {/*                    <DepartmentsLinks*/}
            {/*                        dep={categories}*/}
            {/*                        func={handleButtonClick}*/}
            {/*                        setOpen={setOpen}*/}
            {/*                        pageProps={props as IProps}*/}
            {/*                    />*/}
            {/*                )*/}
            {/*            }*/}
            {/*        </PropsConsumer>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <button
                type="button"
                className={cls.departments__button}
                onClick={handleButtonClick}
            >
                <DepartmentsSvg/>
                <span className={cls["category-title-fms"]}>
                    <FormattedMessage
                        id="Menu.category"
                        defaultMessage="Category"/>
                </span>
            </button>
        </div>
    );
}

export default memo(Departments);

